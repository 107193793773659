import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
  //컨테이너 리스트 정보 가져오기
  getContainerList (data) {
    return Send({
      url: '/trans/cargo-tracking/' + data.blNo,
      method: 'get',
      params: {
        dtKnd: data.dtKnd,
        blNo: data.blNo
      }
    })
  },
  //컨테이너 상세정보 가져오기
  getContainerDetail (data) {
    return Send({
      url: '/trans/cargo-tracking/' + data.blNo + '/detail',
      method: 'get',
      params: {
        bkgNo: data.bkgNo,
        cntrNo: data.cntrNo,
        dtKnd: data.dtKnd,
        strBkgNo: data.strBkgNo
      }
    })
  },
  //맵 정보 가져오기
  getMapDetail (blNo) {
    return Send({
      url: '/trans/cargo-tracking/' + blNo + '/map',
      method: 'get'
    })
  },
  //rteCd 정보 가져오기
  getRtnCode (params) {
    return Send({
      url: '/trans/cargo-tracking/findRteCd',
      method: 'get',
      params: params
    })
  },
  getBookingClose (bkgNo) {
    return Send({
      url: '/trans/cargo-tracking/' + bkgNo + '/close-info',
      method: 'get'
    })
  },
  //rteCd 정보 가져오기
  getPortDetailInfo (params) {
    return Send({
      url: '/trans/cargo-tracking/findPortDetailInfo',
      method: 'get',
      params: params
    })
  },
  // 컨테이너별 추가정보 가져오기
  getPodTrmlNm (podSchSeq) {
    return Send({
      url: '/trans/cargo-tracking/pod-trml-nm',
      method: 'get',
      params: {
        schSeq: podSchSeq
      }
    })
  },
  // 컨테이너별 추가정보 가져오기
  getCntrEtdEtaInfo (blNo) {
      return Send({
        url: '/trans/cargo-tracking/cntr-etd-eta-info',
        method: 'get',
        params: {
          blNo: blNo
        }
      })
  }
}
